export type ErrorMessage = string;
export type Result<T> = T | ErrorMessage;

export interface Spot {
    id: string;
    from: string;
    to: string;
    price: number;
    notes: string;
}

export interface ReservationProposal {
    spots: Spot[];
    price: number;
    from: string;
    to: string;
}

export interface AnonymousReservation {
    reservationCode: string;
    state: ReservationState;
    from: string;
    to: string;
    note: string;
    price: number;
}

export interface Reservation {
    reservationCode: string;
    state: ReservationState;
    from: string;
    to: string;
    requestedAt: Date;
    bookedAt?: Date;
    cancelledAt?: string;
    note: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    amountOfPeople: number;
    normalPrice: number;
    price: number;
    peopleInfo: string;
}

export enum ReservationState {
    InReview = 0,
    Booked = 1,
    CancelledByCustomer = 2,
    CancelledByAdmin = 3
}

export interface BookrApi {
    getUnavailableDatesAsync(): Promise<Result<string[]>>;
    getValidStartDatesAsync(): Promise<Result<string[]>>;
    getProposalsAsync(startDate: string): Promise<Result<ReservationProposal[]>>;
    sendReservationRequestAsync(request: ReservationRequest): Promise<Result<Reservation>>;
    getReservationByCodeAsync(reservationCode: string): Promise<Result<AnonymousReservation>>;
    cancelReservationAsync(reservationCode: string): Promise<void>;
}

export interface ReservationGuest {
    name: string;
    birthDate: string;
}

export interface ReservationRequest {
    from: string;
    to: string;
    customerFirstname: string;
    customerLastname: string;
    customerEmail: string;
    customerPhone: string;
    customerBirthDate: string;
    guests: ReservationGuest[];
    price: number;
    spotIds: string[];
}

export class Api implements BookrApi {
    protected readonly url;

    constructor(baseUrl: string) {
        this.url = baseUrl;
    }

    async getUnavailableDatesAsync(): Promise<Result<string[]>> {
        const result = await this.getAsync(`${this.url}/reservations/dates/unavailable`);
        if (result instanceof Response)
            return await result.json() as string[];
        return result;
    }

    async getProposalsAsync(startDate: string): Promise<Result<ReservationProposal[]>> {
        const result = await this.getAsync(`${this.url}/reservations/recommend/forstartdate/${startDate}`);
        if (result instanceof Response)
            return await result.json() as ReservationProposal[];
        return result;
    }

    async getValidStartDatesAsync(): Promise<Result<string[]>> {
        const result = await this.getAsync(`${this.url}/reservations/dates/available`);
        if (result instanceof Response)
            return await result.json() as string[];
        return result;
    }

    async sendReservationRequestAsync(request: ReservationRequest): Promise<Result<Reservation>> {
        const response = await this.postAsync(`${this.url}/reservations`, request);
        if (response instanceof Response)
            return await response.json();
        return response;
    }

    async getReservationByCodeAsync(reservationCode: string): Promise<Result<AnonymousReservation>> {
        const response = await this.getAsync(`${this.url}/reservations/bycode/${reservationCode}`);
        if (response instanceof Response)
            return await response.json();
        return response;
    }

    async cancelReservationAsync(reservationCode: string): Promise<void> {
        await this.deleteAsync(`${this.url}/reservations/bycode/${reservationCode}`);
    }

    async getAsync(url: string): Promise<Result<Response>> {
        try {
            return await fetch(url);
        }
        catch (e: unknown) {
            return this.errorToString(e) ?? "Error sending get request";
        }
    }
    async deleteAsync(url: string): Promise<Result<Response>> {
        try {
            return await fetch(url, {
                method: 'DELETE'
            });
        } catch (e: unknown) {
            return this.errorToString(e) ?? "Error sending delete request";
        }
    }

    async postAsync(url: string, body: unknown): Promise<Result<Response>> {
        try {
            return await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
        } catch (e: unknown) {
            return this.errorToString(e) ?? "Error sending post request";
        }
    }
    errorToString(error: unknown): string | undefined {
        if (typeof error === "string") {
            return error;
        } else if (error instanceof Error) {
            return error.message;
        } else if (error instanceof Object && 'message' in error) {
            return error as { 'message': string }['message'];
        }
        return undefined;
    }

}

export function createApiInstance(): BookrApi {
    const DEBUG = process.env.NODE_ENV === 'development';
    if (DEBUG) return new Api('https://localhost:5001');
    return new Api('https://api.vakantiehuissylvain.be');
}