import { defineStore } from 'pinia'
import { computed, Ref, ref } from "vue";
import { createApiInstance, ReservationProposal } from '@/services/bookr-api';

export const useReservationStore = defineStore('ReservationStore', () => {
    const api = createApiInstance();

    const showForm = ref(false);
    const availableStartDates: Ref<string[]> = ref([]);
    const proposals: Ref<ReservationProposal[]> = ref([]);
    const takenDates = ref<string[]>([]);

    const proposalEndDates = computed(() => proposals.value.map(p => p.to));

    async function initializeAsync() {
        const takenDatesResult = await api.getUnavailableDatesAsync();
        if (typeof takenDatesResult === 'string')
            throw new Error(takenDatesResult);
        takenDates.value = takenDatesResult;

        const dateResult = await api.getValidStartDatesAsync();
        if (typeof dateResult === 'string')
            throw new Error(dateResult);
        availableStartDates.value = dateResult;
    }

    async function refreshProposalsAsync(startDate: string): Promise<void> {
        const asDate = new Date(startDate);
        const proposalResult = await api.getProposalsAsync(asDate.toISOString());
        if (typeof proposalResult === 'string') {
            proposals.value = [];
            throw new Error(proposalResult);
        }
        proposals.value = proposalResult;
    }

    return {
        takenDates, availableStartDates, proposals, proposalEndDates, initializeAsync, refreshProposalsAsync, showForm
    }

});