import { Reservation, Result } from "@/services/bookr-api";
import { BookrApi } from "@/services/bookr-api";
import { Api } from "@/services/bookr-api";

export interface AdminAPI extends BookrApi {
    getReservationsAsync(): Promise<Result<Reservation[]>>;
    adminCancelReservationAsync(reservationId: string): Promise<Result<boolean>>;
    adminApproveReservationAsync(reservationId: string): Promise<Result<boolean>>;
}

class AdminAPIService extends Api implements AdminAPI {
    private readonly authCode: string = '';

    constructor(url: string, authCode: string) {
        super(url);
        this.authCode = authCode;
    }

    async getReservationsAsync(): Promise<Result<Reservation[]>> {
        const result = await this.getAsync(`${this.url}/admin/reservations`);
        if (result instanceof Response && result.ok)
            return await result.json() as Reservation[];

        if (result instanceof Response)
            return result.statusText;
        return result;
    }


    async adminCancelReservationAsync(reservationId: string): Promise<Result<boolean>> {
        const result = await this.getAsync(`${this.url}/admin/reservations/cancel/${reservationId}`);
        if (result instanceof Response)
            return result.ok;
        return result;
    }

    async adminApproveReservationAsync(reservationId: string): Promise<Result<boolean>> {
        const result = await this.getAsync(`${this.url}/admin/reservations/approve/${reservationId}`);
        if (result instanceof Response)
            return result.ok;
        return result;
    }

    override async getAsync(url: string): Promise<Result<Response>> {
        try {
            return await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': this.authCode
                }
            });
        } catch (e: unknown) {
            return this.errorToString(e) ?? "Error sending post request";
        }
    }

    override errorToString(error: unknown): string | undefined {
        if (typeof error === "string") {
            return error;
        } else if (error instanceof Error) {
            return error.message;
        } else if (error instanceof Object && 'message' in error) {
            return error as { 'message': string }['message'];
        }
        return undefined;
    }
}

export function createApiInstance(authCode: string): AdminAPI {
    const DEBUG = process.env.NODE_ENV === 'development';
    if (DEBUG) return new AdminAPIService('https://localhost:7101', authCode);
    return new AdminAPIService('https://api.vakantiehuissylvain.be', authCode);
}