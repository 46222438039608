import { defineStore } from 'pinia'
import {Ref, ref, computed, ComputedRef} from "vue";

export const usePhotoStore = defineStore('photoStore',  () =>{
    const photos: Ref<string[]> = ref([]);
    const currentIndex: Ref<number> = ref(0);
    const currentPhoto: ComputedRef<string> = computed(() => photos.value[currentIndex.value]);
    const showBigPhoto: Ref<boolean> = ref(false);

    function setIndex(index: number){
        currentIndex.value = index;
    }

    function goNext(){
        if (currentIndex.value < photos.value.length - 1) {
            currentIndex.value++;
        }
    }

    function goBack(){
        if (currentIndex.value > 0) {
            currentIndex.value--;
        }
    }

    function populateImages(force = false) {
        if (photos.value.length > 0 && !force) return;
        const r = require.context('../assets/gallery', false, /\.(png|jpe?g|svg)$/);
        photos.value = r.keys().map(key => r(key));
    }

    return { photos, currentIndex, currentPhoto, showBigPhoto, setIndex, goNext, goBack, populateImages }
});