import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LocationView from '@/views/LocationView.vue'
import PhotoView from '@/views/PhotoView.vue';
import ContactView from '@/views/ContactView.vue'
import BookingView from '@/views/BookingView.vue'
import VacationHomeView from '@/views/VacationHomeView.vue'
import LookupView from '@/views/LookupView.vue'
import AdminView from '@/views/AdminView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ligging',
    name: 'ligging',
    component: LocationView
  },
  {
    path: '/vakantiehuis',
    name: 'vakantiehuis',
    component: VacationHomeView
  },
  {
    path: '/reservering',
    name: 'reservering',
    component: BookingView
  },
  {
    path: '/fotos',
    name: 'fotos',
    component: PhotoView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/lookup',
    name: 'lookup',
    component: LookupView
  },
  {
    path: '/administratie',
    name: 'admin',
    component: AdminView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
